<ng-template #modalContentWrapper>
  <pxw-card
    size="sm"
    [rounded]="false"
    [contentPaddingEnabled]="!disablePadding"
    [contentScrollEnabled]="contentScrollEnabled"
  >
    <pxw-card-header>
      <div class="header-title">
        <ng-container *ngTemplateOutlet="modalTitle"></ng-container>
        @if (!modalTitle) {
          {{ title }}
        }
      </div>
      <div class="header-actions">
        <ng-content select=".custom-action"></ng-content>
        @if (!hideCloseButton) {
          <pxw-icon
            class="close"
            icon="close"
            color="blue"
            class="ui-clickable"
            (click)="close('dismiss')"
            size="md"
          ></pxw-icon>
        }
      </div>
    </pxw-card-header>
    <div class="body">
      <ng-container *ngTemplateOutlet="modalContent"></ng-container>

      <ng-content></ng-content>
    </div>
    @if (buttonText || modalFooter) {
      <pxw-card-footer>
        <ng-container *ngTemplateOutlet="modalFooter; context: { close }"></ng-container>
        @if (!modalFooter) {
          <pxw-button
            color="blue"
            size="lg"
            display="block"
            (click)="action()"
            [loading]="loading"
            [disabled]="buttonDisabled"
            [rounded]="true"
          >
            <pxw-icon icon="check" />
            {{ buttonText }}
          </pxw-button>
        }
      </pxw-card-footer>
    }
  </pxw-card>
  @if (isOpen && bindModalToHistory) {
    <pxw-modal-history
      [name]="modalFormName"
      [title]="title"
      [isBoundToRoute]="isBoundToRoute"
      (popState)="close('backbutton')"
    ></pxw-modal-history>
  }
</ng-template>
