import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  booleanAttribute,
  inject,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import clsx from 'clsx';
import { IconInfoComponent } from '@pedix-workspace/angular-ui-icons';
import { InputMaskModule, InputmaskOptions, createMask } from '@ngneat/input-mask';
import {
  WithDefaultValidatorProps,
  composeValidatorFn,
  hasChangedAnyDefaultValidatorProps,
} from '../utils/defaultValidator';
import { UiFormReactiveConfigService } from '../form-reactive-config.service';

let INPUT_UID = 0;

export type InputNumberLabelSize = 'xs' | 'sm' | 'md';

export type InputNumberValueType = number | undefined | null;

export type CurrencyConfig = {
  symbol: string;
  decimals: number;
  groupSeparator: string;
  decimalSeparator: string;
};

@Component({
  selector: 'pxw-input-number',
  standalone: true,
  templateUrl: './input-number.component.html',
  styleUrl: './input-number.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputNumberComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputNumberComponent,
    },
  ],
  imports: [NgClass, IconInfoComponent, InputMaskModule],
})
export class InputNumberComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator, WithDefaultValidatorProps
{
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input({ required: true }) name: string;
  @Input() label: string;
  @Input() labelSize: InputNumberLabelSize = 'sm';
  @Input() helper: string;
  @Input() placeholder = '';
  @Input() autofocus: boolean;
  @Input() messages: { [key: string]: string } = {};
  @Input() displayErrors = false;
  @Input() displaySpinners: boolean;
  @Input() format: 'currency';
  @Input() allowDecimals = true;

  @Input({ transform: booleanAttribute }) required?: boolean;
  @Input() min?: number;
  @Input() max?: number;

  private injector = inject(Injector);

  protected value: InputNumberValueType;
  protected inputId: string;
  protected isDisabled: boolean;
  protected currencyMask: InputmaskOptions<number>;

  private onChange: (value: InputNumberValueType) => void;
  private onTouched: () => void;
  private onValidatorChange: () => void;
  private validatorFn: ValidatorFn | null;
  private formReactiveConfigService = inject(UiFormReactiveConfigService);

  @HostBinding('class') get classNames() {
    return clsx('number-field', 'ui-input', `ui-input-number`, {
      'ui-input--with-helper': this.helper,
      'ui-input--with-errors': !this.isValid,
    });
  }

  get formControl() {
    const ngControl = this.injector.get(NgControl, null);

    if (ngControl) {
      return ngControl.control as FormControl;
    }
    return null;
  }

  get inputType() {
    if (this.format === 'currency') {
      return 'text';
    }
    return 'number';
  }

  get isRequired() {
    return this.formControl?.hasValidator(Validators.required) || this.required === true;
  }

  get isValid() {
    return this.formControl?.valid;
  }

  get isTouched() {
    return this.formControl?.touched;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get errorEntries(): [string, any][] {
    return Object.entries(this.formControl?.errors || {});
  }

  get shouldDisplayErrors() {
    return (this.displayErrors || this.isTouched) && !this.isValid && !this.isDisabled;
  }

  get currencyConfig(): CurrencyConfig {
    return this.formReactiveConfigService.getCurrencyConfig();
  }

  ngOnInit(): void {
    this.inputId = `input-number-${++INPUT_UID}`;

    if (this.format === 'currency') {
      const currencyConfig = this.currencyConfig;

      this.currencyMask = createMask<number>({
        alias: 'numeric',
        groupSeparator: currencyConfig.groupSeparator,
        radixPoint: currencyConfig.decimalSeparator,
        digits: this.allowDecimals ? currencyConfig.decimals : 0,
        digitsOptional: false,
        prefix: `${currencyConfig.symbol} `,
        placeholder: '0',
        rightAlign: false,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChangedAnyDefaultValidatorProps(changes)) {
      this.validatorFn = composeValidatorFn(this);

      if (this.formControl) {
        this.formControl.updateValueAndValidity();
      }
    }
  }

  writeValue(value: InputNumberValueType) {
    this.value = value;
  }
  registerOnChange(onChange: (value: InputNumberValueType) => void) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validatorFn?.(control) || null;
  }
  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  onKeyUp($event: Event) {
    this.onInputChange($event);
  }

  onInputChange($event: Event) {
    const rawValue = (<HTMLInputElement>$event.target).value;
    const parsedValue =
      this.format === 'currency' ? this.parseCurrency(rawValue) : this.parseNumber(rawValue);
    const newValue = parsedValue ? Number(parsedValue) : null;

    if (!this.currencyMask) {
      this.writeValue(newValue);
    }
    this.onChange(newValue);
    this.onValidatorChange();
    this.onTouched();
  }

  focus() {
    this.input.nativeElement.focus();
  }

  onFocusOut($event: Event) {
    const rawValue = (<HTMLInputElement>$event.target).value;
    const parsedValue = this.format === 'currency' ? this.parseCurrency(rawValue) : rawValue;
    const newValue = parsedValue ? Number(parsedValue) : null;

    if (this.currencyMask) {
      this.writeValue(newValue);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getErrorMessage([key, data]: [string, any]) {
    return this.messages?.[key] || this.formReactiveConfigService.getErrorMessage(key, data);
  }
  private parseCurrency(value: string | null) {
    if (!value) {
      return null;
    }
    const currencyConfig = this.currencyConfig;

    return value
      .replace(currencyConfig.symbol, '')
      .replace(currencyConfig.groupSeparator, '')
      .replace(currencyConfig.decimalSeparator, '.');
  }
  private parseNumber(value: string | null) {
    if (!value) {
      return null;
    }
    if (!this.allowDecimals) {
      return parseInt(value, 10);
    }
    return value;
  }
}
